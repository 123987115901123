import { UserHubClient } from '../api'
import { Auth } from 'aws-amplify';

const getError = (errors) => {
    if(typeof errors === "string")
    {
        return errors;
    }
    else if(Array.isArray(errors))
    {
        return errors[0].description;
    }
    else
    {
        return 'An unknown error occurred.';
    }
}

export default {
    logout: () => {
        Auth.signOut();
    },
    getUsers: async (status) => {
        try {
            return await UserHubClient.searchUsers(status);
        } catch (e) {
            throw getError(e);
        }
    },
    getReports: async (status) => {
        try {
            return await UserHubClient.searchUsers(status);
        } catch (e) {
            throw getError(e);
        }
    },
    getUser: async (userId) => {
        try {
            return await UserHubClient.getUser(userId);
        } catch (e) {
            throw getError(e);
        }
    },
    resendInvitationForUser: async (userId) => {
        try {
            return await UserHubClient.resendInvitationForUser(userId);
        } catch (e) {
            throw getError(e);
        }
    },
    updateUser: async (user) => {
        try {
            return await UserHubClient.updateUser(user);
        } catch (e) {
            throw getError(e);
        }
    }
}