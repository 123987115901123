export default class UserStatus {
    static get Pending(){
        return 'Pending';
    }

    static get Active(){
        return 'Active';
    }

    static get Inactive(){
        return 'Inactive';
    }

    static get Denied(){
        return 'Denied';
    }
}