import React from 'react';
import { LayoutFloating } from '../parts/LayoutFloating';
import flexStyles from '../styles/flexStyles';

const ErrorPage = (props) => {
    const { error, onRefresh } = props;

    return (
        <LayoutFloating>
            <div className="card-body">
                <div className="row justify-content-center">
                    <h1 className="mt-4 mb-4">Connection Failed</h1>
                    <div className="col-9">
                        <p className="lead mb-5 text-center">
                            {error}
                        </p>
                    </div>
                </div>
                <div className="row" style={flexStyles.horizontalContainer}>
                    <div style={flexStyles.flexGrow(1)} />
                    {onRefresh && <button onClick={() => onRefresh()} className="btn btn-primary btn-min-width">
                        Click Here to Retry
                        </button>}
                    <div style={flexStyles.flexGrow(1)} />
                </div>
            </div>
        </LayoutFloating>
    );
}

export default ErrorPage;