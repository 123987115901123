
import Amplify, { Auth } from 'aws-amplify';

export function Configure(config) {
  Amplify.configure({
    Auth: {
      region: 'us-east-2',  
      userPoolId: config.aws.userPoolId,
      userPoolWebClientId: config.aws.userPoolWebClientId
    },
    API: {
      endpoints: [
          {
              name: "PAHubGatewayAPI",
              endpoint: `${config.apis.userhub.base}`,
              custom_header: async () => { 
                  return { Authorization: `Bearer ${  (await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken } `}
              }
          }
        ]
      }
  });
}