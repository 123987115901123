function isNullOrUndefined(e) {
    return typeof e === 'undefined' || e === null;
}

function isNullOrUndefinedOrEmpty(e){
    return isNullOrUndefined(e) || e === ''
}

export default {
    isNullOrUndefined,
    isNullOrUndefinedOrEmpty
};

