import { HttpClient } from '.';

export default class UserHubClient {
  static _config;
  static _client;

  static get userController()  {
    return `${UserHubClient.config.apis.userhub.base}${UserHubClient.config.apis.userhub.version}${UserHubClient.config.apis.userhub.brand}${UserHubClient.config.apis.userhub.user}`;
  }

  static get httpClient(){
    if(UserHubClient._client){
      return UserHubClient._client;
    };

    UserHubClient._client = new HttpClient(UserHubClient.config.fetchMode);
    return UserHubClient._client;
  }

  static set config(newConfig) {
    UserHubClient._config = newConfig;
  }

  static get config() {
    return UserHubClient._config;
  }

  static searchUsers = (status) => {
    const url = `${UserHubClient.userController}/${UserHubClient.config.apis.userhub.search}`;

    const body = {
      user_status: status
    };

    return UserHubClient.httpClient.post(url, body);
  }

  static getUser = (userId) => {
    const url = `${UserHubClient.userController}/${userId}`;
  
    return UserHubClient.httpClient.get(url);
  }

  static resendInvitationForUser = (userId) => {
    const url = `${UserHubClient.userController}/${userId}/${UserHubClient.config.apis.userhub.resendInvitation}`;
  
    return UserHubClient.httpClient.post(url);
  }

  static updateUser = (user) => {
    const url = `${UserHubClient.userController}/${user.user_id}`;
  
    return UserHubClient.httpClient.patch(url, user);
  }
}