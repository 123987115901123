import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar';
import { UserList } from '.';
import {
  PENDING,
  ACTIVE,
  INACTIVE
} from "../../constants"

class Home extends Component {
  state = {
    tabIndex: 0
  };

  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex });
  };

  render() {
    const {tabIndex} = this.state

    return (
        <div>
          <AppBar position="static">
            <Tabs value={tabIndex} onChange={this.handleChange}>
              <Tab label="Pending Account Requests" />
              <Tab label="Active Accounts" />
              <Tab label="Inactive Accounts" />
            </Tabs>
          </AppBar>
          {tabIndex === 0 &&  <UserList statusType = {PENDING}/> }
          {tabIndex === 1 &&  <UserList statusType = {ACTIVE}/> }
          {tabIndex === 2 &&  <UserList statusType = {INACTIVE}/>  }
        </div>
    );
  }
}


export default Home;