import { NullCheckHelpers } from '.';

const required = value => (!NullCheckHelpers.isNullOrUndefined(value) ? undefined : "Required");

const maxLength = max => value => (NullCheckHelpers.isNullOrUndefined(value) || value.length <= max) ? undefined : "Field too long.";

const isValidNpi = value => {
//Implements a version of the Luhn algorithm for validating NPIs:
//https://geekbacon.com/2013/01/15/validating-npi/

  //Only digits or dashes
    if (/[^0-9-\s]+/.test(value) || !/^\d{10}$/.test(value)) return true ? 'Npi is invalid' : undefined;

    //Step 1
    let calculationString = value.substring(0, 9);
    const checkDigit = parseInt(value.charAt(9), 10);
    //Step 2
    calculationString = `80840${calculationString}`;

    let even = true;
    const doubledEvens = [];
    const unaffectedOdds = [];

    for (let n = calculationString.length - 1; n >= 0; n--) {
        const checkDigit = calculationString.charAt(n);
        const numericDigit = parseInt(checkDigit, 10);

        //Step 3
        if (even) {
            (numericDigit * 2).toString().split('').forEach(digit => {
                doubledEvens.push(parseInt(digit, 10));
            });
        } else {
            unaffectedOdds.push(numericDigit);
        }

        even = !even;
    }

    const sum = (total, number) => (total || 0) + number;

    //Step 4
    const sumOfDoubledValues = doubledEvens.reduce(sum);
    const sumOfUnaffectedValues = unaffectedOdds.reduce(sum);
    const total = sumOfDoubledValues + sumOfUnaffectedValues;
    const nearest10 = Math.ceil(total / 10) * 10;

    //Step 5
    const difference = nearest10 - total;

    //Step 6
    return checkDigit !== difference ? 'Npi is invalid' : undefined ;
}


export default {
    required,
    maxLength,
    isValidNpi
};