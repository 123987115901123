import React from 'react';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DetailedView } from '.';
import XLSX from 'xlsx';
import Moment from 'moment';
import { PENDING } from "../../constants"
import GetApp from '@material-ui/icons/GetApp';
import Actions from '../../utilities/Actions';

const sortNames = (a, b) => {
  if (a.last_name < b.last_name) {
    return -1;
  }
  if (a.last_name > b.last_name) {
    return 1;
  }
  if (a.first_name < b.first_name) {
    return -1;
  }
  if (a.first_name > b.first_name) {
    return 1;
  }
}

const UserList = ({ statusType }) => {
  const [users, setUsers] = React.useState(undefined);
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [selectedUserId, setSelectedUserId] = React.useState(undefined);

  const getUsers = () => {
    setSelectedUserId(undefined);
    setErrorMessage(undefined);
    
    return Actions.getUsers(statusType)
      .then(response => setUsers(response))
      .catch(reason => setErrorMessage(reason))
  }

  React.useEffect(() => {
    setSelectedUserId(undefined);
    setErrorMessage(undefined);

    let cancelled = false;
    
    Actions.getUsers(statusType)
      .then(response => !cancelled && setUsers(response))
      .catch(reason => setErrorMessage(reason));

    return () => { cancelled = true };
  }, [statusType]);

  if (errorMessage) {
    return (<div style={{ width: '100%', backgroundColor: 'black', color: 'white' }}>{errorMessage}</div>);
  }
  else if (!users) {
    return (
      <div style={{ position: 'absolute', top: '40%', left: '45%' }}>
        <CircularProgress size={150} thickness={1} />
      </div>);
  }
  else {
    if (statusType === PENDING)
      users.sort((a, b) => new Date(a.request_date) - new Date(b.request_date));
    else
      users.sort(sortNames);

    return (
      <Typography component="div">
        <DetailedView statusType={statusType} onCloseClicked={() => setSelectedUserId(undefined)} userId={selectedUserId} onSubmit={getUsers} />
        <ReportDownload statusType={statusType} />
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f9a825' }}>
              {statusType === PENDING && <TableCell style={{ fontSize: '14pt' }}>Date/Time</TableCell>}
              <TableCell style={{ fontSize: '14pt' }}>First Name</TableCell>
              <TableCell style={{ fontSize: '14pt' }}>Last Name</TableCell>
              <TableCell style={{ fontSize: '14pt' }}>Email</TableCell>
              <TableCell style={{ fontSize: '14pt' }}>Phone Number</TableCell>
              <TableCell style={{ fontSize: '14pt' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => <UserRow key={user.user_id} statusType={statusType} user={user} onClick={setSelectedUserId} />)}
          </TableBody>
        </Table>
      </Typography>
    );
  }
}

const UserRow = ({ statusType, user, onClick }) => {
  return (
    <TableRow key={user.id}>
      {statusType === PENDING && <TableCell> {Moment(user.request_date).format('MMM D YYYY, h:mm:ss a')} </TableCell>}
      <TableCell>{user.first_name}</TableCell>
      <TableCell>{user.last_name}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.phone_number}</TableCell>
      <TableCell>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => { onClick(user.user_id) }}>
          {statusType === PENDING ? 'View' : 'Edit'}
        </Button>
      </TableCell>
    </TableRow>
  )
}

const ReportDownload = ({statusType}) => {
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  const getReports = (statusType) => {
    setErrorMessage(undefined);
    Actions.getUsers(statusType)
      .then(response => exportFile(response, statusType))
      .catch(reason => setErrorMessage(reason));
  }

  return (
    <div style={{ float: "right" }}>
      <Button color="primary" onClick={() => { getReports(statusType) }}>
        Download xlsx
        <GetApp />
      </Button>
      {errorMessage && <div style={{ color: 'red', marginRight: '7px' }}>Error Downloading xlsx file</div>}
    </div>)
}

const exportFile = (data, status) => {
  const rowData = data.map(user => 
    user.npis.map(npi => [
      npi.npi,
      npi.first_name,
      npi.last_name,
      user.first_name,
      user.last_name,
      user.email,
      user.phone_number
    ])).reduce((previous, current) => previous.concat(current));
  const headers = ['Npi', 'First Name', 'Last Name', 'User First Name', 'User Last Name', 'Email', 'Phone Number'];
  const xlsxData = [headers, ...rowData];

  const workSheet = XLSX.utils.aoa_to_sheet(xlsxData);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, status.toUpperCase() + " Users and NPIs");
  XLSX.writeFile(workBook, "UserRegistrations.xlsx")
}

export default (UserList);