export default {
    verticalContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    horizontalContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexGrow(growBy) {
        return {
            flexGrow: growBy
        };
    },
    flexGrowInline(growBy) {
        return {
            ...this.flexGrow(growBy),
            display: 'inline-block'
        };
    }
};