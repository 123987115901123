import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Form, Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlined';
import ApproveIcon from '@material-ui/icons/Check';
import EmailIcon from '@material-ui/icons/Email';
import Typography from '@material-ui/core/Typography';
import { PENDING, ACTIVE, INACTIVE } from '../../constants';
import _ from 'lodash';
import { UserStatus } from '../../shared/constants/index';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Actions } from '../../utilities';
import { Validators } from '../../utilities';
import { FORM_ERROR } from 'final-form';
import ErrorDisplay from '../parts/ErrorDisplay';
import CircularProgress from '@material-ui/core/CircularProgress';


function Transition(props) {
  return <Slide direction='up' {...props} />;
}

const DetailedView = ({ statusType, userId, onCloseClicked, onSubmit }) => {
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [user, setUser] = React.useState(undefined);

  React.useEffect(() => {
    setErrorMessage(undefined);

    if (!userId)
      return;

    let cancelled = false;

    Actions.getUser(userId)
      .then(response => !cancelled && setUser(response))
      .catch(reason => setErrorMessage(reason));

    return () => { cancelled = true };
  }, [userId]);

  const handleClose = () => {
    onCloseClicked();
  };

  const handleSubmit = async (user) => {
    return Actions.updateUser(user)
      .then(() => onSubmit())
      .catch(reason => { return { [FORM_ERROR]: reason } });
  };

  const handleResendInvitation = () => {
    user.user_id = userId;
    Actions.resendInvitationForUser(userId);
  }

  const disabledFields = statusType === INACTIVE ? true : false;

  let dialogContent;
  if(errorMessage) {
    dialogContent = (
      <React.Fragment>
        <AppBar position='static'>
          <Toolbar>
            <span style={{ flex: '1 1 auto' }} />
            <Button color='inherit' onClick={handleClose} size='small'>
              <CloseIcon />
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ width: '100%', backgroundColor: 'black', color: 'white', textAlign: 'center'}}>{errorMessage}</div>
      </React.Fragment>
    );
  }
  else if(!user) {
    dialogContent = 
    (
      <div style={{ position: 'absolute', top: '40%', left: '45%' }}>
        <CircularProgress size={150} thickness={1} />
      </div>
    );
  }
  else {
    dialogContent =
    (
      <Form
      initialValues={user}
      mutators={{ ...arrayMutators }}
      subscription={{
        values: true,
        submitting: true,
        submitError: true
      }}
      onSubmit={handleSubmit}
      render={({
        form: {
          mutators: { push, pop },
          change
        },
        values,
        submitting,
        handleSubmit,
        submitError
      }) =>
        <form onSubmit={handleSubmit}>
          <AppBar position='static'>
            <Toolbar>
              <h2>Account Details</h2>
              <span style={{ flex: '1 1 auto' }} />
              {errorMessage && (
                <Typography align='center' variant='headline' style={{ color: 'red' }}>
                  {errorMessage}
                </Typography>
              )}
              {statusType === ACTIVE && user && !user.confirmed &&
                <Button color='inherit' onClick={handleResendInvitation} size='small' disabled={submitting}>
                  <EmailIcon />
                  Resend Invitation
            </Button>}
              {statusType === PENDING ? <AppBarButton disabled={false} text={'Approve'} icon={<ApproveIcon />} onClick={() => change('user_status', UserStatus.Active)} />
                : statusType === ACTIVE ? <AppBarButton disabled={false} text={'Save'} icon={<ApproveIcon />} />
                  : <AppBarButton disabled={false} text={'Reactivate'} icon={<ApproveIcon />} onClick={() => change('user_status', UserStatus.Active)} />}
              {statusType === PENDING ? <AppBarButton disabled={false} text={'Deny'} icon={<CancelIcon />} onClick={() => change('user_status', UserStatus.Denied)} />
              : statusType === INACTIVE ? null 
              : <AppBarButton disabled={false} text={'Deactivate'} icon={<CancelIcon />} onClick={() => change('user_status', UserStatus.Inactive)} />}
          <Button color='inherit' onClick={handleClose} size='small' disabled={submitting}>
            <CloseIcon />
            Close
          </Button>
            </Toolbar>
          </AppBar>
          <ErrorDisplay error={submitError} />
          <Paper>
            <List>
              <ListItem>
                <Field name='first_name' 
                validate={Validators.required}>
                  {({ input, meta }) => (
                    <TextField
                      autoComplete='off'
                      id={input.name}
                      placeholder='First Name'
                      label='First Name'
                      error={meta.invalid}
                      helperText={meta.error}
                      style={{ margin: '0 1.5%', width: '20%' }}
                      inputProps={{ maxLength: 35 }}
                      disabled={disabledFields}
                      {...input}
                    />
                  )}
                </Field>
                <Field name='last_name'
                  validate={Validators.required}>
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      autoComplete='off'
                      id={input.name}
                      placeholder='Last Name'
                      label='Last Name'
                      error={meta.invalid}
                      helperText={meta.error}
                      style={{ margin: '0 1.5%', width: '20%' }}
                      //inputProps={{ maxLength: 35 }}
                      disabled={disabledFields}
                    />
                  )}
                </Field>
                <Field name={'email'}
                validate={Validators.required}>
                  {({ input, meta }) => (
                    <TextField
                      autoComplete='off'
                      id={input.name}
                      placeholder='Email'
                      label='Email'
                      error={meta.invalid}
                      helperText={meta.error}
                      style={{ margin: '0 1.5%', width: '20%' }}
                      inputProps={{ maxLength: 35 }}
                      disabled={disabledFields}
                      {...input}
                    />
                  )}
                </Field>
                <Field name={'phone_number'}
                validate={Validators.required}>
                  {({ input, meta }) => (
                    <TextField
                      autoComplete='off'
                      id={input.name}
                      placeholder='(xxx) xxx - xxxx'
                      label='Phone Number'
                      error={meta.invalid}
                      helperText={meta.error}
                      style={{ margin: '0 1.5%', width: '20%' }}
                      inputProps={{ maxLength: 35 }}
                      disabled={disabledFields}
                      {...input}
                    />
                  )}
                </Field>
                {statusType !== INACTIVE && (
                  <Button
                    color='inherit'
                    variant='contained'
                    onClick={() => push('npis', {})}
                    size='small'
                    style={{ margin: '0 1%' }}>
                    Add prescriber
                    </Button>
                )}
              </ListItem>
            </List>
          </Paper>
          {user &&
            <Paper autoComplete='off'>
              <List autoComplete='off'>
                <FieldArray name="npis">
                  {({ fields }) =>
                    <React.Fragment>
                      {fields &&
                        fields.map((_, index) => <PrescriberRow key={index} index={index} onDelete={() => fields.remove(index)} disabled={statusType === INACTIVE} canDelete={fields.length > 1} />)}
                    </React.Fragment>
                  }
                </FieldArray>
              </List>
            </Paper>
          }
        </form>
      } />
    )
  }

  return (
    <div>
      <Dialog fullScreen open={userId !== undefined} onClose={handleClose} TransitionComponent={Transition}>
        {dialogContent}
      </Dialog>
    </div>
  );
}

const PrescriberRow = ({ index, onDelete, disabled, canDelete }) => {
  return (
    <div>
      <Field name={`npis[${index}].npi`}
        validate={Validators.isValidNpi}>
        {({ input, meta }) => (
          <TextField
            autoComplete='off'
            id={input.name}
            placeholder='Prescriber Npi'
            label='Prescriber NPI'
            error={meta.invalid}
            helperText={meta.error}
            style={{ margin: '0 1.5%', width: '20%' }}
            inputProps={{ maxLength: 35 }}
            disabled={disabled}
            {...input}
          />
        )}
      </Field>
      <Field name={`npis[${index}].first_name`}
      validate={Validators.required}>
        {({ input, meta }) => (
          <TextField
            autoComplete='off'
            id={input.name}
            placeholder='Prescriber First Name'
            label='Prescriber First Name'
            error={meta.invalid}
            helperText={meta.error}
            style={{ margin: '0 1.5%', width: '20%' }}
            inputProps={{ maxLength: 35 }}
            disabled={disabled}
            {...input}
          />
        )}
      </Field>
      <Field name={`npis[${index}].last_name`}
      validate={Validators.required}>
        {({ input, meta }) => (
          <TextField
            autoComplete='off'
            id={input.name}
            placeholder='Prescriber Last Name'
            label='Prescriber Last Name'
            error={meta.invalid}
            helperText={meta.error}
            style={{ margin: '0 1.5%', width: '20%' }}
            inputProps={{ maxLength: 35 }}
            disabled={disabled}
            {...input}
          />
        )}
      </Field>
      {!disabled && canDelete &&
        <Button title="Remove Npi" onClick={onDelete}>
          <RemoveIcon color="primary" size="small" variant="round" />
        </Button>
      }
    </div>
  );
}

const AppBarButton = ({ disabled, text, icon, onClick }) => {
  return (<Button color='inherit' type="submit" size='small' disabled={disabled} onClick={onClick}>
    {icon}
    {text}
  </Button>
  )
}

export default (DetailedView);
