import React from 'react';

const localConfig =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? require('./config.json')
    : null;

const ConfigContext = React.createContext({ localConfig });

const Configured = ({onConfigChange, children, ConnectionFailedPage, LoadingPage }) => {
  const [config, setConfig] = React.useState(localConfig);
  const [error, setError] = React.useState(undefined);

  const _onConfigChange = _config => {
    if (!_config) {
      return;
    }
    setConfig(_config);

    onConfigChange(_config);
  };

  React.useEffect(() => {
    if (!config) {
      fetch('/config.json')
        .then(
          response =>
            response.status === 200
              ? response
                  .json()
                  .then(json => _onConfigChange(json))
                  .catch(reason => setError(reason))
              : setError('Failed to connect.')
        )
        .catch(reason => setError(reason));
    } else {
      _onConfigChange(config);
    }
  },[]);

  if (error) {
    return <ConnectionFailedPage />;
  }

  if (!config) {
    return <LoadingPage />;
  }

  return (
    <ConfigContext.Provider value={{config, error}}>
      {children}
    </ConfigContext.Provider>
  );
}

const withConfig = (
  WrappedComponent,
  onConfigChange,
  LoadingPage,
  ConnectionFailedPage
) => {
  return () => {
    return (
      <Configured
        onConfigChange={onConfigChange}
        LoadingPage={LoadingPage}
        ConnectionFailedPage={ConnectionFailedPage}
      >
        <WrappedComponent />
      </Configured>
    );
  };
};

const useConfig = () => {
  return React.useContext(ConfigContext).config;
};

export default {
  withConfig,
  ConfigContext,
  useConfig
};