import { Auth } from 'aws-amplify';

async function callFetch(url, options) {
    const response = await fetch(url, options);

    if (response.ok) return await response.json();
    else throw await response.json();
}

const setupOptions = async (fetchMode, method, body = null, options = {}) => {
    const token = (await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken;

    var retOptions = {
        mode: fetchMode,
        method: method,
        ...options,
        headers: {
            Accept: 'application/json',
            ...options.headers
        }
    };

    if (token) {
        retOptions.headers['Authorization'] = `Bearer ${token}`;
    }

    if (body) {
        retOptions.body = body;
    }

    return retOptions;
}

export default class HttpClient {
    constructor(fetchMode) {
        this._fetchMode = fetchMode;
    }

    async get(url) {
        return await callFetch(url, await setupOptions(this._fetchMode, 'GET'));
    }

    async post(url, body) {
        return await callFetch(url,
            await setupOptions(this._fetchMode,
                'POST',
                JSON.stringify(body),
                { headers: { 'Content-Type': 'application/json' } }));

    }

    async patch(url, body) {
        return await callFetch(url,
            await setupOptions(this._fetchMode,
                'PATCH',
                JSON.stringify(body),
                { headers: { 'Content-Type': 'application/json' } }));
    }
}