import React, { Component } from 'react';

export default class NoMatch extends Component {
    render() {
        return (
        <div>
            <h1>Oops, This Page Could Not Be Found!</h1>
            <div class="error-message">404</div>
        </div>);  
    }
}