import React from 'react';

export class LayoutFloating extends React.Component {
  render() {
    return (
      <div className="container layout--floating">
        <div className="card main-content">{this.props.children}</div>
      </div>
    );
  }
}

export default LayoutFloating;
