import React from 'react';

export default function ErrorDisplay(props) {
    if (props.error) {
        return (
            <div className="row justify-content-center" {...props}>
                <p style={errorText}>{props.error}</p>
            </div>
        )
    }
    return (<span />)
}

export const errorText = {
    color: 'red',
    fontWeight: 'bold'
};