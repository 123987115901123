import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {  Home, NoMatch } from './components/pages';
import { Authenticator } from 'aws-amplify-react';
import {Auth} from 'aws-amplify';
import IdleTimer from 'react-idle-timer'
import * as authorization  from './ConfigureAmplify';
import { default as Config } from './config';
import ErrorPage from './components/pages/ErrorPage';
import { UserHubClient } from './api';
import { SignUp } from 'aws-amplify-react/dist/Auth';


const IDLE_TIME = 1000 * 60 * 15;  // timeout in milliseconds

const ConnectionFailedPage = (props) => {
  return <ErrorPage error="Connection to the server has failed. Please click the button below or refresh your browser. If you continue to receive this error, please contact your supervisor." onRefresh={() => window.location.reload()} />
}

const AppWithAuth = () => {
  return (
    <div>
      <Authenticator hide={[SignUp]}>
        <App />
      </Authenticator>
    </div>
  );
};

const onConfigChange = (config) => {
  UserHubClient.config = config;
  authorization.Configure(config);
}


const App = (props) => {
  const onIdle = (e) => {
    Auth.signOut();
  }

  if (props.authState !== 'signedIn') {
    return null;
  }

    return (
      <div className="App">
        <IdleTimer
          element={document}
          onIdle={onIdle}
          timeout={ IDLE_TIME } />
         <BrowserRouter>
           <Switch>
                <Route exact path="/" component={Home}/>
                <Route component={NoMatch} />
              </Switch>
          </BrowserRouter>      
      </div>
    );
  }
 export default Config.withConfig(AppWithAuth, onConfigChange, ConnectionFailedPage);